import { Card as CardPrimitive } from '@radix-ui/themes'
import React from 'react'
import { cn } from '#app/utils/misc'

const Card = React.forwardRef<
	React.ElementRef<typeof CardPrimitive>,
	React.ComponentProps<typeof CardPrimitive>
>(({ children, className, ...props }, ref) => {
	return (
		<CardPrimitive
			data-testid="card"
			ref={ref}
			className={cn('m-2', className)}
			style={{ display: 'flex' }}
			{...props}
		>
			{children}
		</CardPrimitive>
	)
})
Card.displayName = 'Card'

const MainCard = React.forwardRef<
	React.ElementRef<typeof CardPrimitive>,
	React.ComponentProps<typeof CardPrimitive>
>(({ children, className, ...props }, ref) => {
	return (
		<div
			data-testid="main-card"
			ref={ref}
			className={cn('main-card', className)}
			style={{ display: 'flex' }}
			{...props}
		>
			{children}
		</div>
	)
})
MainCard.displayName = 'MainCard'

const InnerFullCard = React.forwardRef<
	React.ElementRef<typeof CardPrimitive>,
	React.ComponentProps<typeof CardPrimitive>
>(({ children, ...props }, ref) => {
	return (
		<CardPrimitive
			ref={ref}
			data-testid="inner-full-card"
			className={cn('flex grow flex-col', props.className)}
			{...props}
		>
			{children}
		</CardPrimitive>
	)
})
InnerFullCard.displayName = 'InnerFullCard'

const EmptyCard = React.forwardRef<
	React.ElementRef<typeof CardPrimitive>,
	React.ComponentProps<typeof CardPrimitive>
>(({ children, className, ...props }, ref) => {
	return (
		<CardPrimitive
			ref={ref}
			data-testid="empty-state-card"
			className={cn('empty-state grow', className)}
			style={{ display: 'flex' }}
			{...props}
		>
			{children}
		</CardPrimitive>
	)
})
EmptyCard.displayName = 'EmptyCard'

export { Card, MainCard, InnerFullCard, EmptyCard }
